<template>
    <div>
        <b-row>
            <b-col>
                <b-overlay :show="!dataLoaded">
                    <b-card>

                        <b-button variant="primary" @click="addModalActive = true">
                            <feather-icon
                                icon="PlusIcon"
                                class="mr-50"
                            />
                            <span class="align-middle">Add</span>
                        </b-button>

                        <basic-table :columns="columns" :data="stays" v-slot="props">
                            <div v-if="props.column.displayType === 2" class="d-flex justify-content-center">
                                <b-button variant="success" @click="selectedUser = props.row" class="mr-1">
                                    <feather-icon
                                        icon="ListIcon"
                                    />
                                </b-button>
                                <b-button variant="danger" @click="removeStay(props.row.id)">
                                    <feather-icon
                                        icon="Trash2Icon"
                                    />
                                </b-button>
                            </div>
                            <span v-else-if="props.column.displayType === 1" class="d-flex justify-content-center">
                                {{ (Number(props.formattedRow[props.column.field]) / 100).toFixed(2) }}
                            </span>
                            <span v-else class="d-flex justify-content-center">
                                {{ props.formattedRow[props.column.field] }}
                            </span>

                        </basic-table>
                    </b-card>
                </b-overlay>
            </b-col>
            <b-col v-if="selectedUser">
                <b-card>
                    <h4>{{selectedUser.name}}</h4>
                    <h6>{{moment(selectedUser.check_in).format('YYYY/MM/DD HH:mm')}} - {{moment(selectedUser.check_out).format('YYYY/MM/DD HH:mm')}}</h6>

                    <hr/>
                    <h5 class="mb-1">User bill</h5>
                    <b-table :items="selectedUser.bill_items" :fields="userBillFields"/>

                    <h6 class="mt-2 ml-1">Total: {{selectedUserBillTotal}}</h6>

                </b-card>
            </b-col>
        </b-row>


        <b-modal title="Add new" v-model="addModalActive" no-close-on-backdrop>
            <template #default>
                <b-form-group>
                    <label>Name:</label>
                    <b-form-input
                        type="text"
                        placeholder="Name"
                        v-model="addObject.name"
                    />
                </b-form-group>

                <b-form-group>
                    <label>Check in:</label>
                    <flat-pickr
                        v-model="addObject.check_in"
                        class="form-control"
                        :config="{ enableTime: true, dateFormat: 'Y-m-d H:i', time_24hr: true}"
                    />
                </b-form-group>
                <b-form-group>
                    <label>Check out:</label>
                    <flat-pickr
                        v-model="addObject.check_out"
                        class="form-control"
                        :config="{ enableTime: true, dateFormat: 'Y-m-d H:i', time_24hr: true}"
                    />
                </b-form-group>
                <b-form-group>
                    <label>Room:</label>
                    <v-select
                        v-model="addObject.room_id"
                        :reduce="room => room.id"
                        :filter="filterRooms"
                        label="name"
                        :options="rooms"
                    >
                        <template slot="option" slot-scope="room">
                            <span>{{room.location + ' - ' + room.name}}</span>
                        </template>

                        <template slot="selected-option" slot-scope="room">
                            <span>{{room.location + ' - ' + room.name}}</span>
                        </template>
                    </v-select>
                </b-form-group>
            </template>

            <template #modal-footer>

                <b-button variant="danger" @click="addModalActive = false">
                    <feather-icon
                        icon="XIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Close</span>
                </b-button>

                <b-button variant="primary" @click="addStay">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Add</span>
                </b-button>
            </template>
        </b-modal>

    </div>
</template>
<script>

    import BasicTable from '@/views/components/BasicTable'
    import {BButton, BCard, BCol, BFormGroup, BFormInput, BModal, BOverlay, BRow, BTable} from 'bootstrap-vue'
    import flatPickr from 'vue-flatpickr-component'
    import vSelect from 'vue-select'

    export default {
        components: {
            BRow,
            BCol,
            BasicTable,
            BOverlay,
            BCard,
            BButton,
            BModal,
            BFormInput,
            BFormGroup,
            BTable,
            flatPickr,
            vSelect
        },
        data() {
            return {

                stays: [],
                rooms: [],
                dataLoaded: false,

                selectedUser: null,

                columns: [
                    {
                        label: 'ID',
                        displayType: 0,
                        field: 'id',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search ID'
                        }
                    },
                    {
                        label: 'Name',
                        displayType: 0,
                        field: 'name',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search Name'
                        }
                    },
                    {
                        label: 'Check in',
                        displayType: 0,
                        field: 'check_in',
                        formatFn: this.timeRenderer,
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Check in'
                        }
                    },
                    {
                        label: 'Check out',
                        displayType: 0,
                        field: 'check_out',
                        formatFn: this.timeRenderer,
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Check out'
                        }
                    },
                    {
                        label: 'Room',
                        displayType: 0,
                        field: this.roomRenderer,
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search Room'
                        }
                    },
                    {
                        label: 'Actions',
                        displayType: 2,
                        field: 'actions',
                        filterOptions: {
                            enabled: false
                        }
                    }
                ],

                userBillFields: [
                    {
                        key: 'name'
                    },
                    {
                        key: 'quantity'
                    },
                    {
                        key: 'price_one',
                        formatter: (value) => (Number(value) / 100).toFixed(2)
                    },
                    {
                        key: 'price_total',
                        formatter: (value) => (Number(value) / 100).toFixed(2)
                    }
                ],

                addObject: {},
                addModalActive: false

            }
        },
        methods: {
            loadData() {
                this.dataLoaded = false
                const thisIns = this
                const loadPromise = this.$http.get('/api/management/v1/fake_erp/stays')
                loadPromise.then(function(response) {
                    thisIns.stays = response.data
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })

                const roomsPromise = this.$http.get('/api/management/v1/room')
                roomsPromise.then(function(response) {
                    thisIns.rooms = response.data
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })

                Promise.all([loadPromise, roomsPromise]).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            addStay() {
                this.dataLoaded = false
                const thisIns = this
                const loadPromise = this.$http.post('/api/management/v1/fake_erp/stays', {
                    name: this.addObject.name,
                    room_id: this.addObject.room_id,
                    check_in: this.moment(this.addObject.check_in).format(),
                    check_out: this.moment(this.addObject.check_out).format()
                })
                loadPromise.then(function() {
                    thisIns.$printSuccess('Stay added')
                    thisIns.addModalActive = false
                    thisIns.addObject = {}
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.loadData()
                })
            },
            removeStay(id) {
                const thisIns = this
                const loadPromise = this.$http.delete(`/api/management/v1/fake_erp/stays/${  id}`)
                loadPromise.then(function() {
                    thisIns.$printSuccess('Stay removed')
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.loadData()
                })
            },
            timeRenderer(time) {
                return this.moment(time).format('YYYY/MM/DD HH:mm')
            },
            roomRenderer(rowObj) {
                return this.getRoomName(rowObj.room_id)
            },
            getRoomName(roomId) {
                const room = this.rooms.find(room => room.id === roomId)
                return (room) ? `${room.location  } - ${  room.name}` : 'Unknown'
            },
            filterRooms(rooms, search) {
                return rooms.filter(room => room.location.toLowerCase().includes(search.toLowerCase()) || room.name.toLowerCase().includes(search.toLowerCase()))
            }
        },
        computed: {
            selectedUserBillTotal() {
                if (!this.selectedUser) return 0.0

                let total = 0
                for (let x = 0; x < this.selectedUser.bill_items.length; x++) {
                    total += this.selectedUser.bill_items[x].price_total
                }

                return (total / 100).toFixed(2)
            }
        },
        mounted() {
            this.loadData()
        }
    }

</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>